import React, { useState, useEffect } from 'react'
import { Paywall } from '../components/paywall'
import Layout from '../components/layout'

const isServer = typeof window === 'undefined'

if (!isServer) {
  require('@lrfmedia/client-apps-store')
}

const store = isServer ? undefined : window.store

if (!isServer && !store) {
  console.error('Missing store')
}

const EpaperPage = () => {
  const [showPaywall, setShowPaywall] = useState(true)

  useEffect(() => {
    if (store) {
      loadStoreContent(store)
    }

    const unsubscribe = store?.subscribe(() => {
      loadStoreContent(store)
    })

    return unsubscribe
  }, [])

  const loadStoreContent = (store) => {
    const {
      lastAction: { type, payload: profile },
    } = store.getState()

    if (type === 'PROFILE_ACTION') {
      const hasAccess = !!profile?.activeSubscriptions?.find(
        (sub) => sub.categoryType === 'LAM_T' || sub.categoryType === 'LAM_EW'
      )
      setShowPaywall(!hasAccess)
    }
  }

  function redirect(e) {
    e.preventDefault()
    const token = window.store.getState().profile.shortLivedToken
    window.location.href = `https://lantmannen.prenly.com/authority/auth-token?auth_token=${token}`
  }

  return (
    <Layout articlelevel={100}>
      {showPaywall && <Paywall />}
      <div className="contentWrapper flex">
        <div className="content flex emagazine">
          <img
            width={308}
            height={400}
            alt="Etidningen"
            src="https://mediacdn.prenly.com/api/image/lantmannen.prenly.com/3447/latest-issue"
          />
          <a className="magazineRedirectBtn" onClick={redirect} href="">
            Tryck här för att öppna e-tidningen
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default EpaperPage
