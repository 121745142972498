import React from 'react'

const copy = {
  lockerTitle: 'Välkommen till Lantmannens e-tidning!',
  lockerLoginLink: 'Klicka här',
  lockerLoginText: 'för att registrera konto och logga in',
  lockerLoginTextFirstPart: 'Är du prenumerant? Då ingår e-tidningen.',
  lockerList: [
    'Inspiration av professionella kollegor.',
    'Expertråd inom växtodling, teknik och gårdsekonomi.',
    'Följ med till världens fält och internationella marknader.',
  ],
  lockerOffer: 'Prenumerera på Lantmannen',
  lockerSubscribe: 'prenumerera nu',
}

export const Paywall = () => {
  const onActivate = () => {
    const currentUrl = window.location.href
    const newUrl = `${currentUrl}?loginModal=login`
    window.location.href = newUrl
  }
  return (
    <section className="Locker Locker-premium">
      <div className="Locker-wrapper">
        <header className="Locker-header">
          <h2 className="Locker-title">{copy.lockerTitle}</h2>
          <p className="Locker-small">
            {copy.lockerLoginTextFirstPart}{' '}
            <button
              type="button"
              onClick={onActivate}
              className="Locker-link Lrfsso-aktivera-konto-och-prenumeration"
            >
              {copy.lockerLoginLink}
            </button>{' '}
            {copy.lockerLoginText}
          </p>
        </header>

        <div className="Locker-body">
          <div className="Locker-offer">
            <div className="Locker-offer-header">
              <h2 className="Locker-offer-heading">{copy.lockerOffer}</h2>
            </div>

            <ul className="Locker-list">
              {(copy.lockerList || []).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          <a href="https://prenumerera.lantmannen.nu/" className="Locker-button">
            {copy.lockerSubscribe}
          </a>
        </div>

        <footer className="Locker-footer">
          Behöver du hjälp? <br />
          <Contact />
        </footer>
      </div>
    </section>
  )
}
const customerServicePhone = '010-184 40 84'
const customerServiceEmail = 'kundservice@lrfmedia.se'

const Contact = () => (
  <div>
    Var vänlig kontakta kundservice.
    {customerServicePhone && (
      <div>
        Telefon:{' '}
        <a href={`tel:${customerServicePhone}`}>{customerServicePhone}</a>,
      </div>
    )}
    {customerServiceEmail && (
      <div>
        E-post:{' '}
        <a href={`mailto:${customerServiceEmail}`}>{customerServiceEmail}</a>
      </div>
    )}
  </div>
)
